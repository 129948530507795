import { Layout, Button } from '@/components'

export default function Custom404() {
  return (
    <Layout>
      <div className="bg-white dark:bg-zinc-800 min-h-full px-6 py-16 sm:px-6 sm:py-60 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-7xl font-extrabold text-brand-500 sm:text-9xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l dark:border-zinc-600 border-zinc-200 sm:pl-6 py-6">
                <h1 className="text-4xl font-extrabold dark:text-white text-zinc-900 tracking-tight sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-1 text-lg font-medium tracking-tight dark:text-zinc-400 text-zinc-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button internal small url="/">
                  Go back home
                </Button>
                <Button internal small alt url="/contact">
                  Contact support
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}
